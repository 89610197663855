#footer {
    position: relative;
}
.footer-container {
    display: block;
    background-color: #fbf6f1;
    margin: 0;
    padding: 0;
    border: 0;
    .bottom-oval {
        margin-bottom: -4px;
        height: auto;
        width: 100%;
        z-index: 2;
    }
    .bottom-oval__fill {
        fill: #9fa195;
    }
    .container {
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
        max-width: 1240px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        width: 80%;
        justify-content: flex-start;
    }
}
.footer-container__content {
    background-color: #9fa195;
    height: 100px;
    position: relative;
    display: flex;
    align-items: flex-end;
}
.footer-container__column {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 24%;
    z-index: 3;
    margin-bottom: 1%;
    margin-right: 1%;
}
.footer-container__socials {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100%;
    top: 0.4rem;
    li {
        float: left;
        list-style-type: none;
        a img {
            border: none;
            filter: invert(1);
        }
    }
}
.footer-container__paragraph {
    position: relative;
    display: flex;
    font-family: "Montserrat", sans-serif;
    align-items: flex-end;
    height: 100%;
    // z-index: 4;
    h3 {
        margin-bottom: 1px;
        color: whitesmoke;
    }
    a {
        text-decoration: none;
        color: whitesmoke;
    }
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    .footer-container__socials {
        right: 2.5rem;
        width: 40vw;
        li {
            margin-right: 10px;
            a img {
                height: 25px;
            }
        }
    }
    .footer-container__paragraph {
        justify-content: center;
        top: 0.4rem;
        width: 30vw;
        h3 {
            font-size: 14px;
        }
        a {
            font-size: 13px;
            line-height: 1.5;
        }
    }
    .front-labs-tag {
        display: none;
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 932px) and (orientation: landscape) {
    .footer-container__column {
        max-width: 24.5%;
    }
    .footer-container__socials {
        right: 2.5rem;
        width: 30vw;
        li {
            margin-right: 10px;
            a img {
                height: 35px;
            }
        }
    }
    .footer-container__paragraph {
        right: 0.5rem;
        h3 {
            font-size: 18px;
        }
        a {
            font-size: 16px;
            line-height: 1.5;
        }
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    .footer-container__column {
        max-width: 24.5%;
    }
    .footer-container__socials {
        right: 1.5rem;
        li {
            margin-right: 15px;
            a img {
                height: 35px;
            }
        }
    }
    .footer-container__paragraph {
        right: 1.5rem;
        h3 {
            font-size: 20px;
        }
        a {
            font-size: 18px;
            line-height: 1.5;
        }
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    .footer-container__column {
        max-width: 24.5%;
    }
    .footer-container__socials {
        right: 1.5rem;
        li {
            margin-right: 15px;
            a img {
                height: 35px;
            }
        }
    }
    .footer-container__paragraph {
        right: 1.5rem;
        h3 {
            font-size: 20px;
        }
        a {
            font-size: 18px;
            line-height: 1.5;
        }
    }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
    .footer-container__column {
        max-width: 24.5%;
    }
    .footer-container__socials {
        right: 1.5rem;
        li {
            margin-right: 15px;
            a img {
                height: 40px;
            }
            a img:hover {
                opacity: 1;
                -webkit-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
                transition: 400ms ease-in-out;
            }
        }
    }
    .footer-container__paragraph {
        bottom: 0.5rem;
        right: 1.5rem;
        h3 {
            font-size: 21px;
        }
        a {
            font-size: 19px;
            line-height: 1.5;
            &:hover {
                color: black;
                transition: 400ms ease-in-out;
            }
        }
    }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
    .footer-container__column {
        max-width: 24.5%;
    }
    .footer-container__socials {
        right: 1.5rem;
        li {
            margin-right: 15px;
            a img {
                height: 40px;
            }
            a img:hover {
                opacity: 1;
                -webkit-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
                transition: 400ms ease-in-out;
            }
        }
    }
    .footer-container__paragraph {
        bottom: 0.5rem;
        right: 1.5rem;
        h3 {
            font-size: 21px;
        }
        a {
            font-size: 19px;
            line-height: 1.5;
            &:hover {
                color: black;
                transition: 400ms ease-in-out;
            }
        }
    }
}
