#home {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: rgb(250, 245, 239);
}
.image-home {
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.25;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}
.intro-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #710809;
    filter: drop-shadow(1px 2px 5px black);
    border-radius: 50%;
    opacity: 0.8;
    header {
        text-align: center;
        color: whitesmoke;
        letter-spacing: 0.1em;
        font-family: "Roboto", sans-serif;
    }
    h2 {
        text-align: center;
        font-weight: 400;
        letter-spacing: 0.123em;
        line-height: 1.35;
        color: whitesmoke;
        font-family: "Roboto", sans-serif;
    }
}
.button-circle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 5px;
    text-decoration: none;
    text-transform: uppercase;
    color: black;
    background-color: #888b7e;
    font-weight: 600;
}
.social-media-buttons {
    position: absolute;
    li {
        list-style-type: none;
        a img {
            filter: invert(1);
        }
    }
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    .intro-circle {
        width: 450px;
        height: 450px;
        header {
            font-size: 19px;
            margin: 45px 0px 10px 0px;
        }
        h2 {
            width: 275px;
            font-size: 12px;
            margin: 17.5px auto;
        }
    }
    .button-circle {
        width: 90px;
        height: 30px;
        font-size: 12px;
        margin: 20px auto;
        letter-spacing: 0.9px;
    }
    .social-media-buttons {
        bottom: 0;
        right: 2rem;
        li {
            margin: 2px 0px;
            a img {
                width: 35px;
            }
        }
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 932px) and (orientation: landscape) {
    .intro-circle {
        width: 350px;
        height: 350px;
        header {
            font-size: 17px;
            margin: 40px 0px 5px 0px;
        }
        h2 {
            width: 250px;
            font-size: 11px;
            margin: 15px auto;
        }
    }
    .button-circle {
        width: 80px;
        height: 25px;
        font-size: 11px;
        margin: 12.5px auto;
        letter-spacing: 0.5px;
    }
    .social-media-buttons {
        bottom: 0;
        right: 2rem;
        li {
            margin: 2px 0px;
            a img {
                width: 30px;
            }
        }
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    .intro-circle {
        width: 600px;
        height: 600px;
        header {
            font-size: 24px;
            margin: 55px 0px 5px 0px;
        }
        h2 {
            width: 360px;
            font-size: 18px;
            margin: 20px auto;
        }
    }
    .button-circle {
        width: 125px;
        height: 40px;
        font-size: 17px;
        letter-spacing: 0.5px;
        margin: 20px auto;
    }
    .social-media-buttons {
        bottom: 0;
        right: 3rem;
        li {
            margin: 2px 0px;
            a img {
                width: 50px;
            }
        }
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    .intro-circle {
        width: 550px;
        height: 550px;
        header {
            font-size: 23px;
            margin: 55px 0px 5px 0px;
        }
        h2 {
            width: 360px;
            font-size: 16px;
            margin: 20px auto;
        }
    }
    .button-circle {
        width: 125px;
        height: 35px;
        font-size: 17px;
        letter-spacing: 0.5px;
        margin: 20px auto;
    }
    .social-media-buttons {
        right: 2rem;
        bottom: 0;
        li {
            margin: 11px 0px;
            a img {
                width: 45px;
            }
        }
    }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
    .intro-circle {
        width: 550px;
        height: 550px;
        header {
            font-size: 23px;
            font-weight: 600;
            margin: 55px 0px 10px 0px;
        }
        h2 {
            width: 335px;
            font-size: 16px;
            margin: 17.5px auto;
        }
    }
    .button-circle {
        width: 125px;
        height: 35px;
        font-size: 17px;
        margin: 20px auto;
        letter-spacing: 1.2px;
        &:hover {
            opacity: 1;
            color: whitesmoke;
            transition: ease-in-out 400ms;
        }
    }
    .social-media-buttons {
        bottom: 0;
        right: 2rem;
        li {
            margin: 11px 0px;
            a img {
                width: 35px;
            }
            a img:hover {
                opacity: 1;
                -webkit-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
                transition: 400ms ease-in-out;
            }
        }
    }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
    .intro-circle {
        width: 600px;
        height: 600px;
        header {
            font-size: 24px;
            font-weight: 600;
            margin: 65px 0px 10px 0px;
        }
        h2 {
            width: 350px;
            font-size: 18px;
            margin: 17.5px auto;
        }
    }
    .button-circle {
        width: 135px;
        height: 45px;
        font-size: 18px;
        margin: 20px auto;
        letter-spacing: 1.2px;
        &:hover {
            opacity: 1;
            color: whitesmoke;
            transition: ease-in-out 400ms;
        }
    }
    .social-media-buttons {
        bottom: 0;
        right: 2rem;
        li {
            margin: 11px 0px;
            a img {
                width: 35px;
            }
            a img:hover {
                opacity: 1;
                -webkit-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
                transition: 400ms ease-in-out;
            }
        }
    }
}
