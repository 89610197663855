#who-am-i {
    position: relative;
    width: 100vw;
    background-color: rgb(250, 245, 239);
}
.image-who-is {
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.25;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}
.circle-who-is {
    position: absolute;
    left: 50%;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #888b7e;
    opacity: 0.9;
    h2 {
        position: absolute;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        letter-spacing: 0.123em;
        text-align: center;
        color: white;
    }
    img {
        position: absolute;
        border-radius: 10px;
    }
}
.footer-container-who {
    position: absolute;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    bottom: 0;
    .bottom-oval-who {
        width: 100%;
        height: auto;
        margin-bottom: -4px;
        z-index: 2;
    }
    .bottom-oval__fill-who {
        fill: #8e4745;
    }
    .container-who {
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
        max-width: 1240px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        width: 80%;
        justify-content: flex-start;
    }
}
.footer-container-who__content {
    position: relative;
    display: flex;
    align-items: flex-end;
    background-color: #8e4745;
    height: 100px;
}
.footer-container-who__column {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 24%;
    margin-bottom: 1%;
    margin-right: 1%;
    z-index: 3;
}
.footer-container-who__socials {
    position: relative;
    display: flex;
    align-items: flex-end;
    top: 0.4rem;
    height: 100%;
    li {
        float: left;
        list-style-type: none;
        a img {
            border: none;
            filter: invert(1);
        }
    }
}
.footer-container-who__paragraph {
    position: relative;
    font-family: "Montserrat", sans-serif;
    z-index: 4;
    h3 {
        color: whitesmoke;
        margin-bottom: 1px;
    }
    a {
        color: whitesmoke;
        text-decoration: none;
        line-height: 1.5;
    }
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    #who-am-i {
        height: 100vh;
    }
    .circle-who-is {
        top: 41%;
        width: 350px;
        height: 350px;
        filter: drop-shadow(1px 2px 3px black);
        .mobile-who-is {
            position: absolute;
            width: 70%;
            top: 9%;
            left: 16%;
            font-family: "Roboto", sans-serif;
            font-size: 9.5px;
            font-weight: 400;
            letter-spacing: 0.123em;
            text-align: center;
            line-height: 1.3;
        }
        .text-indent {
            position: absolute;
            width: 69%;
            top: 50%;
            left: 26%;
            font-family: "Roboto", sans-serif;
            font-size: 9.5px;
            font-weight: 400;
            letter-spacing: 0.123em;
            line-height: 1.3;
        }
        img {
            width: 23%;
            top: 11rem;
            right: 17rem;
        }
    }
    .summary-mo {
        display: none;
    }
    .footer-container-who__socials {
        right: 2.5rem;
        width: 40vw;
        li {
            margin-right: 10px;
            a img {
                height: 25px;
            }
        }
    }
    .footer-container-who__paragraph {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        top: 0.4rem;
        width: 30vw;
        height: 100%;
        h3 {
            font-size: 14px;
        }
        a {
            font-size: 13px;
        }
    }
    .front-labs-tag {
        display: none;
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 932px) and (orientation: landscape) {
    #who-am-i {
        height: 110vh;
    }
    .circle-who-is {
        top: 47%;
        width: 450px;
        height: 450px;
        filter: drop-shadow(1px 2px 6px black);
        h2 {
            width: 72%;
            top: 8%;
            left: 16%;
            font-size: 13px;
            line-height: 1.3;
        }
        img {
            top: 25%;
            right: 90%;
            width: 34%;
        }
    }
    .mobile-who-is {
        display: none;
    }
    .text-indent {
        display: none;
    }
    .footer-container-who__column {
        max-width: 24.5%;
    }
    .footer-container-who__socials {
        right: 1.5rem;
        width: 30vw;
        li {
            margin-right: 15px;
            a img {
                height: 40px;
            }
        }
    }
    .footer-container-who__paragraph {
        bottom: 0.3rem;
        left: 2.5rem;
        h3 {
            font-size: 19px;
        }
        a {
            font-size: 17px;
        }
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    #who-am-i {
        height: 100vh;
    }
    .circle-who-is {
        top: 40%;
        width: 450px;
        height: 450px;
        filter: drop-shadow(1px 2px 6px black);
        h2 {
            width: 70%;
            top: 8%;
            left: 16%;
            font-size: 13px;
            line-height: 1.3;
        }
        img {
            top: 25%;
            right: 90%;
            width: 34%;
        }
    }
    .mobile-who-is {
        display: none;
    }
    .text-indent {
        display: none;
    }
    .footer-container-who__column {
        max-width: 24.5%;
    }
    .footer-container-who__socials {
        right: 1.5rem;
        li {
            margin-right: 15px;
            a img {
                height: 40px;
            }
            a img:hover {
                opacity: 1;
                -webkit-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
                transition: 400ms ease-in-out;
            }
        }
    }
    .footer-container-who__paragraph {
        display: flex;
        align-items: flex-end;
        bottom: 0.5rem;
        right: 1.5rem;
        height: 100%;
        h3 {
            font-size: 21px;
        }
        a {
            font-size: 19px;
            &:hover {
                color: black;
                transition: 400ms ease-in-out;
            }
        }
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    #who-am-i {
        height: 100vh;
    }
    .circle-who-is {
        top: 40%;
        width: 450px;
        height: 450px;
        filter: drop-shadow(1px 2px 6px black);
        h2 {
            width: 70%;
            top: 8%;
            left: 16%;
            font-size: 13px;
            line-height: 1.3;
        }
        img {
            top: 25%;
            right: 90%;
            width: 34%;
        }
    }
    .mobile-who-is {
        display: none;
    }
    .text-indent {
        display: none;
    }
    .footer-container-who__column {
        max-width: 24.5%;
    }
    .footer-container-who__socials {
        right: 1.5rem;
        li {
            margin-right: 15px;
            a img {
                height: 40px;
            }
            a img:hover {
                opacity: 1;
                -webkit-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
                transition: 400ms ease-in-out;
            }
        }
    }
    .footer-container-who__paragraph {
        display: flex;
        align-items: flex-end;
        bottom: 0.5rem;
        right: 1.5rem;
        height: 100%;
        h3 {
            font-size: 21px;
        }
        a {
            font-size: 19px;
            &:hover {
                color: black;
                transition: 400ms ease-in-out;
            }
        }
    }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
    #who-am-i {
        height: 120vh;
    }
    .circle-who-is {
        top: 43%;
        width: 500px;
        height: 500px;
        filter: drop-shadow(1px 2px 6px black);
        h2 {
            width: 72%;
            top: 9%;
            left: 15%;
            font-size: 14px;
            line-height: 1.5;
        }
        img {
            top: 25%;
            right: 90%;
            width: 37%;
        }
    }
    .mobile-who-is {
        display: none;
    }
    .text-indent {
        display: none;
    }
    .footer-container-who__column {
        max-width: 24.5%;
    }
    .footer-container-who__socials {
        right: 1.5rem;
        li {
            margin-right: 15px;
            a img {
                height: 40px;
            }
            a img:hover {
                opacity: 1;
                -webkit-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
                transition: 400ms ease-in-out;
            }
        }
    }
    .footer-container-who__paragraph {
        display: flex;
        align-items: flex-end;
        bottom: 0.5rem;
        right: 1.5rem;
        height: 100%;
        h3 {
            font-size: 21px;
        }
        a {
            font-size: 19px;
            &:hover {
                color: black;
                transition: 400ms ease-in-out;
            }
        }
    }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
    #who-am-i {
        height: 120vh;
    }
    .circle-who-is {
        top: 43%;
        width: 500px;
        height: 500px;
        filter: drop-shadow(1px 2px 6px black);
        h2 {
            width: 72%;
            top: 9%;
            left: 15%;
            font-size: 14px;
            line-height: 1.5;
        }
        img {
            top: 25%;
            right: 90%;
            width: 37%;
        }
    }
    .mobile-who-is {
        display: none;
    }
    .text-indent {
        display: none;
    }
    .footer-container-who__column {
        max-width: 24.5%;
    }
    .footer-container-who__socials {
        right: 1.5rem;
        li {
            margin-right: 15px;
            a img {
                height: 40px;
            }
            a img:hover {
                opacity: 1;
                -webkit-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
                transition: 400ms ease-in-out;
            }
        }
    }
    .footer-container-who__paragraph {
        display: flex;
        align-items: flex-end;
        bottom: 0.5rem;
        right: 1.5rem;
        height: 100%;
        h3 {
            font-size: 21px;
        }
        a {
            font-size: 19px;
            &:hover {
                color: black;
                transition: 400ms ease-in-out;
            }
        }
    }
}
