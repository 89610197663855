#review {
    position: relative;
    height: 90vh;
    width: 100vw;
    background-color: rgb(250, 245, 239);
}
.image-reviews {
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.08;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}
.text-balloon {
    position: absolute;
    filter: drop-shadow(1px 2px 10px black);
    h2 {
        position: absolute;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        letter-spacing: 0.123em;
        text-align: center;
        line-height: 1.3;
    }
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    // --> Hoi Monique, ik vind het erg bijzonder..... <-- \\
    .text-balloon {
        top: 23%;
        left: 4%;
        img {
            width: 200px;
        }
        h2 {
            margin: 26px;
            top: 5%;
            font-size: 7px;
        }
    }
    // --> Dat was een eye-opener..... <-- \\
    .text-balloon.two {
        top: 9%;
        left: 27.5%;
        img {
            width: 80px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 12.5px;
            top: 7%;
            font-size: 7px;
        }
    }
    // --> Hi Monique, ik ben nu zes keer bij je..... <-- \\
    .text-balloon.three {
        top: 12.5%;
        left: 55%;
        img {
            width: 145px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 17px;
            top: 12%;
            font-size: 7px;
        }
    }
    // --> Hallo Monique, de opstelling was diepgaand..... <-- \\
    .text-balloon.four {
        top: 70%;
        left: 10%;
        img {
            width: 145px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 20px;
            top: 2.5%;
            font-size: 7px;
        }
    }
    // --> Dag Monique, ondertussen gaat het..... <-- \\
    .text-balloon.five {
        top: 35%;
        left: 45%;
        img {
            width: 210px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 35px;
            top: 1%;
            font-size: 7px;
        }
    }
    // --> Lieve Monique, dank je wel voor..... <-- \\
    .text-balloon.six {
        top: 50%;
        left: 5%;
        img {
            width: 150px;
        }
        h2 {
            margin: 25px;
            top: 2%;
            font-size: 7px;
        }
    }
    // --> Hi Monique. Wat fijn en inzicht..... <-- \\
    .text-balloon.seven {
        top: 65%;
        left: 55%;
        img {
            width: 155px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 23px;
            top: 8%;
            font-size: 7px;
        }
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 932px) and (orientation: landscape) {
    // --> Hoi Monique, ik vind het erg bijzonder..... <-- \\
    .text-balloon {
        top: 15%;
        left: 3.5%;
        img {
            width: 220px;
        }
        h2 {
            margin: 26px;
            top: 5%;
            font-size: 8px;
        }
    }
    // --> Dat was een eye-opener..... <-- \\
    .text-balloon.two {
        top: 40%;
        left: 45%;
        img {
            width: 80px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 4px;
            top: 15%;
            font-size: 8px;
        }
    }
    // --> Hi Monique, ik ben nu zes keer bij je..... <-- \\
    .text-balloon.three {
        top: 7.5%;
        left: 50%;
        img {
            width: 155px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 18px;
            top: 10%;
            font-size: 8px;
        }
    }
    // --> Hallo Monique, de opstelling was diepgaand..... <-- \\
    .text-balloon.four {
        top: 15%;
        left: 27.5%;
        img {
            width: 165px;
        }
        h2 {
            margin: 18px;
            top: 5%;
            font-size: 8px;
        }
    }
    // --> Dag Monique, ondertussen gaat het..... <-- \\
    .text-balloon.five {
        top: 25%;
        left: 72.5%;
        img {
            width: 230px;
        }
        h2 {
            margin: 27px;
            top: 7.5%;
            font-size: 8px;
        }
    }
    // --> Lieve Monique, dank je wel voor..... <-- \\
    .text-balloon.six {
        top: 58.5%;
        left: 27.5%;
        img {
            width: 160px;
        }
        h2 {
            margin: 20px;
            top: 2.5%;
            font-size: 8px;
        }
    }
    // --> Hi Monique. Wat fijn en inzicht..... <-- \\
    .text-balloon.seven {
        top: 50%;
        left: 53.5%;
        img {
            width: 160px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 22.5px;
            top: 5%;
            font-size: 8px;
        }
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    // --> Hoi Monique, ik vind het erg bijzonder..... <-- \\
    .text-balloon {
        top: 10%;
        left: 3.5%;
        img {
            width: 335px;
        }
        h2 {
            margin: 40px;
            top: 5%;
            font-size: 12px;
        }
    }
    // --> Dat was een eye-opener..... <-- \\
    .text-balloon.two {
        top: 37.5%;
        left: 42.5%;
        img {
            width: 125px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 10px;
            top: 12.5%;
            font-size: 12px;
        }
    }
    // --> Hi Monique, ik ben nu zes keer bij je..... <-- \\
    .text-balloon.three {
        top: 7.5%;
        left: 50%;
        img {
            width: 225px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 29px;
            top: 5%;
            font-size: 12px;
        }
    }
    // --> Hallo Monique, de opstelling was diepgaand..... <-- \\
    .text-balloon.four {
        top: 42.5%;
        left: 8.5%;
        img {
            width: 235px;
        }
        h2 {
            margin: 25px;
            top: 5%;
            font-size: 12px;
        }
    }
    // --> Dag Monique, ondertussen gaat het..... <-- \\
    .text-balloon.five {
        top: 55%;
        left: 50%;
        img {
            width: 335px;
        }
        h2 {
            margin: 35px;
            top: 10%;
            font-size: 12px;
        }
    }
    // --> Lieve Monique, dank je wel voor..... <-- \\
    .text-balloon.six {
        top: 65%;
        left: 15%;
        img {
            width: 250px;
        }
        h2 {
            margin: 35px;
            top: 5%;
            font-size: 12px;
        }
    }
    // --> Hi Monique. Wat fijn en inzicht..... <-- \\
    .text-balloon.seven {
        top: 32.5%;
        left: 65%;
        img {
            width: 230px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 29px;
            top: 5%;
            font-size: 12px;
        }
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
     // --> Hoi Monique, ik vind het erg bijzonder..... <-- \\
     .text-balloon {
        top: 15%;
        left: 3.5%;
        img {
            width: 360px;
        }
        h2 {
            margin: 55px;
            top: 1%;
            font-size: 12.5px;
        }
    }
    // --> Dat was een eye-opener..... <-- \\
    .text-balloon.two {
        top: 15.5%;
        left: 35%;
        img {
            width: 125px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 8.5px;
            top: 12.5%;
            font-size: 12.5px;
        }
    }
    // --> Hi Monique, ik ben nu zes keer bij je..... <-- \\
    .text-balloon.three {
        top: 5%;
        left: 50%;
        img {
            width: 250px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 32.5px;
            top: 10%;
            font-size: 12.5px;
        }
    }
    // --> Hallo Monique, de opstelling was diepgaand..... <-- \\
    .text-balloon.four {
        top: 45%;
        left: 30%;
        img {
            width: 255px;
        }
        h2 {
            margin: 32.5px;
            top: 5%;
            font-size: 12.5px;
        }
    }
    // --> Dag Monique, ondertussen gaat het..... <-- \\
    .text-balloon.five {
        top: 40%;
        left: 72.5%;
        img {
            width: 335px;
        }
        h2 {
            margin: 35px;
            top: 10%;
            font-size: 12px;
        }
    }
    // --> Lieve Monique, dank je wel voor..... <-- \\
    .text-balloon.six {
        top: 66%;
        left: 5%;
        img {
            width: 255px;
        }
        h2 {
            margin: 32.5px;
            top: 5%;
            font-size: 12.5px;
        }
    }
    // --> Hi Monique. Wat fijn en inzicht..... <-- \\
    .text-balloon.seven {
        top: 42.5%;
        left: 52.5%;
        img {
            width: 245px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 32.5px;
            top: 5%;
            font-size: 12.5px;
        }
    }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
    // --> Hoi Monique, ik vind het erg bijzonder..... <-- \\
    .text-balloon {
        top: 15%;
        left: 3.5%;
        img {
            width: 420px;
        }
        h2 {
            margin: 50px;
            top: 5%;
            font-size: 15px;
        }
    }
    // --> Dat was een eye-opener..... <-- \\
    .text-balloon.two {
        top: 15.5%;
        left: 35%;
        img {
            width: 135px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 10px;
            top: 20%;
            font-size: 12px;
        }
    }
    // --> Hi Monique, ik ben nu zes keer bij je..... <-- \\
    .text-balloon.three {
        top: 5%;
        left: 50%;
        img {
            width: 325px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 25px;
            top: 17%;
            font-size: 15px;
        }
    }
    // --> Hallo Monique, de opstelling was diepgaand..... <-- \\
    .text-balloon.four {
        top: 45%;
        left: 30%;
        img {
            width: 330px;
        }
        h2 {
            margin: 45px;
            top: 1%;
            font-size: 16px;
        }
    }
    // --> Dag Monique, ondertussen gaat het..... <-- \\
    .text-balloon.five {
        top: 40%;
        left: 72.5%;
        img {
            width: 375px;
        }
        h2 {
            margin: 40px;
            top: 10%;
            font-size: 13px;
        }
    }
    // --> Lieve Monique, dank je wel voor..... <-- \\
    .text-balloon.six {
        top: 66%;
        left: 5%;
        img {
            width: 250px;
        }
        h2 {
            margin: 40px;
            top: 2.5%;
            font-size: 12px;
        }
    }
    // --> Hi Monique. Wat fijn en inzicht..... <-- \\
    .text-balloon.seven {
        top: 65%;
        left: 55%;
        img {
            width: 250px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 40px;
            top: 2.5%;
            font-size: 12px;
        }
    }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
    // --> Hoi Monique, ik vind het erg bijzonder..... <-- \\
    .text-balloon {
        top: 15%;
        left: 3.5%;
        img {
            width: 420px;
        }
        h2 {
            margin: 50px;
            top: 5%;
            font-size: 15px;
        }
    }
    // --> Dat was een eye-opener..... <-- \\
    .text-balloon.two {
        top: 15.5%;
        left: 35%;
        img {
            width: 135px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 10px;
            top: 20%;
            font-size: 12px;
        }
    }
    // --> Hi Monique, ik ben nu zes keer bij je..... <-- \\
    .text-balloon.three {
        top: 5%;
        left: 50%;
        img {
            width: 325px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 25px;
            top: 17%;
            font-size: 15px;
        }
    }
    // --> Hallo Monique, de opstelling was diepgaand..... <-- \\
    .text-balloon.four {
        top: 45%;
        left: 30%;
        img {
            width: 330px;
        }
        h2 {
            margin: 45px;
            top: 1%;
            font-size: 16px;
        }
    }
    // --> Dag Monique, ondertussen gaat het..... <-- \\
    .text-balloon.five {
        top: 40%;
        left: 72.5%;
        img {
            width: 375px;
        }
        h2 {
            margin: 40px;
            top: 10%;
            font-size: 13px;
        }
    }
    // --> Lieve Monique, dank je wel voor..... <-- \\
    .text-balloon.six {
        top: 66%;
        left: 5%;
        img {
            width: 250px;
        }
        h2 {
            margin: 40px;
            top: 2.5%;
            font-size: 12px;
        }
    }
    // --> Hi Monique. Wat fijn en inzicht..... <-- \\
    .text-balloon.seven {
        top: 65%;
        left: 55%;
        img {
            width: 250px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        h2 {
            margin: 40px;
            top: 2.5%;
            font-size: 12px;
        }
    }
}
