#systeem {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: rgb(250, 245, 239);
}
.image-systeem {
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.25;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}
.systeem-circle-1 {
    position: absolute;
    border-radius: 50%;
    background-color: #710809;
    filter: drop-shadow(1px 2px 5px black);
    opacity: 0.8;
    header {
        text-align: center;
        color: whitesmoke;
        font-family: "Roboto", sans-serif;
    }
    h2 {
        text-align: center;
        color: whitesmoke;
        font-family: "Roboto", sans-serif;
    }
}
.systeem-circle-2 {
    position: absolute;
    border-radius: 50%;
    background-color: #888b7e;
    filter: drop-shadow(1px 2px 5px black);
    opacity: 0.8;
    h2 {
        position: relative;
        text-align: center;
        color: black;
        font-family: "Roboto", sans-serif;
    }
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    .systeem-circle-1 {
        width: 340px;
        height: 340px;
        left: 45%;
        top: 20%;
        -webkit-transform: translate(-45%, -20%);
        transform: translate(-45%, -20%);
        header {
            font-size: 12px;
            font-weight: 600;
            margin: 50px 55px 0px 55px;
        }
        h2 {
            width: 210px;
            font-size: 11px;
            font-weight: 100;
            line-height: 1.35;
            margin: 17.5px auto;
        }
    }
    .systeem-circle-2 {
        width: 300px;
        height: 300px;
        left: 60%;
        top: 85%;
        -webkit-transform: translate(-60%, -85%);
        transform: translate(-60%, -85%);
        h2 {
            width: 210px;
            font-size: 11px;
            font-weight: 100;
            line-height: 1.35;
            margin: 50px auto;
        }
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 932px) and (orientation: landscape) {
    .systeem-circle-1 {
        width: 325px;
        height: 325px;
        left: 15%;
        top: 40%;
        -webkit-transform: translate(-15%, -40%);
        transform: translate(-15%, -40%);
        header {
            font-size: 11.5px;
            font-weight: 600;
            margin: 35px 70px 0px 70px;
        }
        h2 {
            width: 210px;
            font-size: 10.5px;
            font-weight: 100;
            line-height: 1.35;
            margin: 17.5px auto;
        }
    }
    .systeem-circle-2 {
        width: 300px;
        height: 300px;
        left: 80%;
        top: 85%;
        -webkit-transform: translate(-80%, -85%);
        transform: translate(-80%, -85%);
        h2 {
            width: 210px;
            font-size: 10.5px;
            font-weight: 100;
            line-height: 1.45;
            margin: 60px auto;
        }
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    .systeem-circle-1 {
        width: 465px;
        height: 465px;
        left: 15%;
        top: 25%;
        -webkit-transform: translate(-15%, -25%);
        transform: translate(-15%, -25%);
        header {
            font-size: 16px;
            font-weight: 600;
            margin: 50px 100px 0px 100px;
        }
        h2 {
            width: 275px;
            font-size: 15px;
            font-weight: 100;
            line-height: 1.2;
            letter-spacing: 0.123em;
            margin: 17.5px auto;
        }
    }
    .systeem-circle-2 {
        width: 400px;
        height: 400px;
        left: 80%;
        top: 85%;
        -webkit-transform: translate(-80%, -85%);
        transform: translate(-80%, -85%);
        h2 {
            width: 275px;
            font-size: 15px;
            font-weight: 100;
            line-height: 1.1;
            letter-spacing: 0.123em;
            margin: 55px auto;
        }
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    .systeem-circle-1 {
        width: 465px;
        height: 465px;
        left: 15%;
        top: 25%;
        -webkit-transform: translate(-15%, -25%);
        transform: translate(-15%, -25%);
        header {
            font-size: 16px;
            font-weight: 600;
            margin: 50px 100px 0px 100px;
        }
        h2 {
            width: 275px;
            font-size: 15px;
            font-weight: 100;
            line-height: 1.2;
            letter-spacing: 0.123em;
            margin: 17.5px auto;
        }
    }
    .systeem-circle-2 {
        width: 400px;
        height: 400px;
        left: 80%;
        top: 85%;
        -webkit-transform: translate(-80%, -85%);
        transform: translate(-80%, -85%);
        h2 {
            width: 275px;
            font-size: 15px;
            font-weight: 100;
            line-height: 1.1;
            letter-spacing: 0.123em;
            margin: 55px auto;
        }
    }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
    .systeem-circle-1 {
        width: 600px;
        height: 600px;
        left: 15%;
        top: 55%;
        -webkit-transform: translate(-15%, -55%);
        transform: translate(-15%, -55%);
        header {
            font-size: 20px;
            font-weight: 600;
            margin: 90px 100px 20px 100px;
        }
        h2 {
            width: 340px;
            font-size: 17px;
            font-weight: 400;
            letter-spacing: 0.123em;
            line-height: 1.35;
            margin: 20px auto;
        }
    }
    .systeem-circle-2 {
        width: 500px;
        height: 500px;
        left: 85%;
        top: 80%;
        -webkit-transform: translate(-85%, -80%);
        transform: translate(-85%, -80%);
        h2 {
            width: 325px;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.123em;
            line-height: 1.35;
            margin: 75px auto;
        }
    }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
    .systeem-circle-1 {
        width: 600px;
        height: 600px;
        left: 15%;
        top: 55%;
        -webkit-transform: translate(-15%, -55%);
        transform: translate(-15%, -55%);
        header {
            font-size: 20px;
            font-weight: 600;
            margin: 90px 100px 20px 100px;
        }
        h2 {
            width: 340px;
            font-size: 17px;
            font-weight: 400;
            letter-spacing: 0.123em;
            line-height: 1.35;
            margin: 20px auto;
        }
    }
    .systeem-circle-2 {
        width: 500px;
        height: 500px;
        left: 85%;
        top: 80%;
        -webkit-transform: translate(-85%, -80%);
        transform: translate(-85%, -80%);
        h2 {
            width: 325px;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.123em;
            line-height: 1.35;
            margin: 75px auto;
        }
    }
}
