.nav-container {
    background-color: black;
    opacity: 0.8;
}
.nav {
    width: 100vw;
    position: fixed;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    transition: 0.5s;
    z-index: 1;
    font-family: "Montserrat", sans-serif;
}
.logo {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
}
.logo-left {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    transition: ease-in-out 400ms;
    img {
        filter: invert(1);
    }
}
.menu {
    position: relative;
    display: flex;
    list-style-type: none;
    a {
        color: black;
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
    }
    button {
        color: black;
        background: none;
        border: none;
        text-transform: uppercase;
        cursor: pointer;
        font-family: "Montserrat", sans-serif;
    }
}
.menu-buttons {
    display: flex;
    transition: ease-in-out 400ms;
    a,
    button {
        color: #fffffe;
        opacity: 1;
    }
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    /// DISPLAY NONE
    .logo {
        display: none;
    }
    .logo-left {
        display: none;
    }
    .nav {
        height: 50px;
    }
    ul.menu.false {
        padding: 0;
        margin: 0;
    }
    .menu {
        width: 100vw;
        justify-content: space-evenly;
        align-items: baseline;
        top: 1.2vh;
        a {
            font-size: 12px;
            font-weight: 600;
        }
        button {
            font-size: 12px;
            font-weight: 600;
        }
    }
    .menu-buttons {
        width: 100vw;
        justify-content: space-evenly;
        align-items: baseline;
        padding: 0;
        top: 0.1vh;
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 932px) and (orientation: landscape) {
    .nav {
        height: 55px;
    }
    ul.logo.false,
    ul.logo.logo-left {
        padding: 0;
        margin: 0;
    }
    .logo {
        top: 2vh;
        img {
            width: 140px;
            padding-left: 1rem;
        }
    }
    .logo-left {
        top: 0.4vh;
        padding-left: 1rem;
    }
    .menu {
        width: 75%;
        justify-content: flex-end;
        align-items: center;
        top: 1vh;
        a {
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 0.08em;
            padding: 25px;
        }
        button {
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 0.08em;
        }
    }
    .menu-buttons {
        width: 50%;
        justify-content: flex-end;
        top: 0.2vh;
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    .nav {
        height: 60px;
    }
    ul.logo.false,
    ul.logo.logo-left {
        padding: 0;
        margin: 0;
    }
    .logo {
        top: 1.2vh;
        img {
            width: 180px;
            padding-left: 1.5rem;
        }
    }
    .logo-left {
        top: 0.1vh;
        img {
            width: 180px;
            padding-left: 1.5rem;
        }
    }
    .menu {
        justify-content: flex-end;
        align-items: baseline;
        width: 100vw;
        top: 1.2vh;
        a {
            font-size: 13px;
            font-weight: 600;
            padding: 20px;
        }
        button {
            font-size: 13px;
            font-weight: 600;
        }
    }
    .menu-buttons {
        width: 100vw;
        justify-content: flex-end;
        align-items: baseline;
        top: 0.1vh;
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    .nav {
        height: 60px;
    }
    ul.logo.false,
    ul.logo.logo-left {
        padding: 0;
        margin: 0;
    }
    .logo {
        top: 1.2vh;
        img {
            width: 180px;
            padding-left: 1rem;
        }
    }
    .logo-left {
        top: 0.1vh;
        img {
            padding-left: 1rem;
        }
    }
    .menu {
        width: 100vw;
        justify-content: flex-end;
        align-items: baseline;
        top: 1.2vh;
        a {
            font-size: 14px;
            font-weight: 600;
            padding: 20px;
        }
        button {
            font-size: 14px;
            font-weight: 600;
        }
    }
    .menu-buttons {
        width: 100vw;
        justify-content: flex-end;
        align-items: baseline;
        top: 0.1vh;
    }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
    .nav {
        height: 80px;
    }
    .logo {
        top: 2vh;
        img {
            width: 220px;
        }
    }
    .logo-left {
        top: 0.4vh;
    }
    .menu {
        justify-content: flex-end;
        align-items: center;
        width: 50%;
        top: 2vh;
        a {
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 0.1em;
            padding: 25px;
            &:hover {
                color: #d77548;
                transition: 400ms ease-in-out;
            }
        }
        button {
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 0.1em;
            &:hover {
                color: #d77548;
                transition: 400ms ease-in-out;
            }
        }
    }
    .menu-buttons {
        width: 50%;
        justify-content: flex-end;
        top: 0.4vh;
    }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
    .nav {
        height: 80px;
    }
    .logo {
        top: 2vh;
        img {
            width: 220px;
        }
    }
    .logo-left {
        top: 0.4vh;
    }
    .menu {
        justify-content: flex-end;
        align-items: center;
        width: 50%;
        top: 2vh;
        a {
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 0.1em;
            padding: 25px;
            &:hover {
                color: #d77548;
                transition: 400ms ease-in-out;
            }
        }
        button {
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 0.1em;
            &:hover {
                color: #d77548;
                transition: 400ms ease-in-out;
            }
        }
    }
    .menu-buttons {
        width: 50%;
        justify-content: flex-end;
        top: 0.4vh;
    }
}
