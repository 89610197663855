@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Montserrat&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

.front-labs-tag {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 75%;
  right: 1rem;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  p {
    color: white;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
