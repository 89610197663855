.nav-who {
    width: 100vw;
    position: fixed;
    display: flex;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    background-color: black;
    opacity: 0.8;
    z-index: 1;
}
.logo-who {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    // top: 0.4vh;
    img {
        filter: invert(1);
    }
}
.menu-who {
    list-style-type: none;
    a {
        color: whitesmoke;
        border-radius: 5px;
        text-decoration: none;
        border: 1.5px solid whitesmoke;
        font-weight: 600;
        letter-spacing: 0.1em;
        opacity: 0.7;
    }
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    .nav-who {
        height: 50px;
    }
    ul.logo-who.false {
        padding: 0;
        margin: 0;
    }
    .logo-who {
        top: 0.1vh;
        img {
            width: 140px;
            padding-left: 1rem;
        }
    }
    .menu-who {
        position: absolute;
        margin-right: 1rem;
        bottom: 0.4vh;
        right: 0;
        a {
            font-size: 7px;
            padding: 4px;
        }
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 932px) and (orientation: landscape) {
    .nav-who {
        height: 55px;
    }
    ul.logo-who.false {
        padding: 0;
        margin: 0;
    }
    .logo-who {
        top: 0.4vh;
        img {
            width: 140px;
            padding-left: 1rem;
        }
    }
    .menu-who {
        position: absolute;
        margin-right: 1rem;
        bottom: 0.9vh;
        right: 0;
        a {
            font-size: 7px;
            padding: 4px;
        }
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    .nav-who {
        height: 60px;
    }
    ul.logo-who.false {
        padding: 0;
        margin: 0;
    }
    .logo-who {
        top: 0.1vh;
        img {
            width: 180px;
            padding-left: 1.5rem;
        }
    }
    .menu-who {
        position: absolute;
        margin-right: 1.5rem;
        right: 0;
        // bottom: 0.9vh;
        // margin-bottom: 25px;
        a {
            font-size: 8px;
            padding: 5px;
        }
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    .nav-who {
        height: 60px;
    }
    ul.logo-who.false {
        padding: 0;
        margin: 0;
    }
    .logo-who {
        top: 0.1vh;
        img {
            width: 180px;
            padding-left: 1rem;
        }
    }
    .menu-who {
        position: absolute;
        margin-right: 2rem;
        right: 0;
        // margin-bottom: 25px;
        a {
            font-size: 10px;
            padding: 5px;
        }
    }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
    .nav-who {
        height: 80px;
    }
    .logo-who {
        top: 0.4vh;
        img {
            width: 220px;
        }
    }
    .menu-who {
        position: absolute;
        margin-right: 2rem;
        right: 0;
        a {
            font-size: 13.5px;
            padding: 6px;
            &:hover {
                color: coral;
                transition: 400ms ease-in-out;
            }
        }
    }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
    .nav-who {
        height: 80px;
    }
    .logo-who {
        top: 0.4vh;
        img {
            width: 220px;
        }
    }
    .menu-who {
        position: absolute;
        margin-right: 2rem;
        right: 0;
        a {
            font-size: 13.5px;
            padding: 6px;
            &:hover {
                color: coral;
                transition: 400ms ease-in-out;
            }
        }
    }
}
